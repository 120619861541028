import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect } from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";

export default () => {
  const [emojiString, setEmojiString] = useState("🚀🍍⚡️");
  const [quantity, setQuantity] = useState(130);
  const [copyText, setCopyText] = useState("Copy to Clipboard");

  useEffect(() => {
    setTimeout(() => setCopyText("Copy to Clipboard"), 2000);
  }, [copyText]);
  const result = () => {
    if (emojiString === "") {
      return emojiString;
    }

    return emojiString
      .repeat(quantity)
      .split("")
      .splice(0, quantity)
      .join("");
  };

  const copied = () => {
    setCopyText("Copied!");
  };

  const textResult = result();
  return (
    <Layout>
      <SEO title="Generator" />
      <h1 className="margin-3 is-hero-menu is-blue">Build a Wall.</h1>
      <div className="margin-3-l line is-blue" />
      <div className="row">
        <div
          className="col-xs-12 pad-5"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4 className="margin-2-b margin-0">Enter your desired emoji:</h4>
          <input
            placeholder="The More the better"
            className="is-hero-sub-text"
            value={emojiString}
            onChange={e => {
              setEmojiString(e.target.value);
            }}
          />
        </div>
        <div
          className="col-xs-12 pad-5-l pad-5-r "
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4 className="margin-2-b margin-0">Quantity:</h4>
          <input
            placeholder="100"
            className="is-hero-sub-text"
            value={quantity}
            onChange={e => {
              setQuantity(
                parseInt(e.target.value)
                  ? parseInt(e.target.value) > 1500
                    ? 1500
                    : parseInt(e.target.value)
                  : 0
              );
            }}
          />
        </div>
        <div
          className="col-xs-12 pad-5"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4 className="margin-0 margin-5-b">Preview:</h4>
          <textarea disabled value={textResult} style={{ height: 300 }} />
        </div>
        <div
          className="col-xs-12 pad-5"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <CopyToClipboard
            text={
              textResult +
              "\nWall Generated by https://emojiwall.sld.codes \n - \n Not affiliated with Donald Trump."
            }
            onCopy={() => copied()}
          >
            <button className="btn is-blue-bg">
              <h1 className="margin-0">{copyText}</h1>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </Layout>
  );
};
